import React from 'react'
import { Avatar, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import Colors from '#/styles/Colors'

const StyledAvatar = styled(Avatar)(({ plan }) => ({
  ...(plan === 'monitoring' ? Colors.plan.monitoring : Colors.plan.cowconnect),
  width: '30px',
  height: '30px',
  fontSize: '0.8rem',
}))

const TypePlansBadge = ({ value }) => {
  if (!value) return <span>-</span>

  const plan = value?.status

  /* CowMed Monitoramento/ CowConnect */
  let letters = plan === 'monitoring' ? 'CM' : 'CC'

  return (
    <Tooltip title={<span>{value?.text}</span>}>
      <StyledAvatar plan={plan}>{letters}</StyledAvatar>
    </Tooltip>
  )
}
export default TypePlansBadge
