import React, { useState, forwardRef, useMemo } from 'react'
import { isEmpty, isEqual } from 'lodash'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import Icon from '@mdi/react'
import {
  mdiFaceAgent,
  mdiChartLine,
  mdiThermometerLines,
  mdiWifi,
  mdiAccessPointNetwork,
  mdiChevronUp,
  mdiChevronDown,
  mdiShieldLock,
} from '@mdi/js'
import { ListItemButton } from '@mui/material'
import { getCurrentFarmSlug } from '#/store/ducks/farm'
import { routerMatch } from '#/utils/routerMatch'
import isBusinessEnvironment from '#/utils/isBusinessEnvironment'
import { usePermissions } from '#/store/hooks/permissions'
import Translate from '#/components/Translate'
import Palette from '#/styles/Old/Palette'
import AnimalIcon from '#/icons/Animal'
import HealthIcon from '#/icons/Health'
import ProductionIcon from '#/icons/Production'
import { getUserInfo } from '#/store/ducks/auth'
import RearingIcon from '#/icons/Rearing'
import ReproductionIcon from '#/icons/Reproduction'
import NutritionIcon from '#/icons/Nutrition'
import HandlingIcon from '#/icons/Handling'
import CTechIcon from '#/icons/CTech'
import CowComfortIcon from '#/icons/CowComfort'
import ImportIcon from '#/icons/Import'
import ExportIcon from '#/icons/Export'
import DataAnalyticsIcon from '#/icons/DataAnalytics'
import FarmDatabase from '#/icons/FarmDatabase'
import AccountIcon from '#/icons/Account'
import HomeIcon from '#/icons/Home'

const useStyles = makeStyles(theme => ({
  listRoot: {
    paddingBottom: 0,
  },
  subHeader: {
    lineHeight: '28px',
    fontSize: '.9em',
  },
  parent: {
    color: Palette.white.self,
  },
  nested: {
    paddingLeft: theme.spacing(7),
    paddingTop: '5px',
    paddingBottom: '5px',
    color: Palette.brown_grey.lighten[2],
  },
  linkHover: {
    '&:hover svg': {
      color: Palette.primary.lighten[1],
    },
    '&:hover span': {
      color: Palette.primary.lighten[1],
    },
    '&:hover': {
      backgroundColor: Palette.transparent[2],
    },
  },
  icon: {
    color: Palette.primary.accent[2],
    minWidth: 35,
    marginTop: 4,
    marginBottom: 4,
  },
}))

const Menu = () => {
  const classes = useStyles()
  const { has_company, farm_count } = useSelector(getUserInfo, isEqual)

  const [open, setOpen] = useState()
  const currentFarmSlug = useSelector(getCurrentFarmSlug, isEqual)

  const { permissions } = usePermissions({
    system: [
      'access_telescope',
      'create_company',
      'create_farm',
      'list_campaign',
      'list_account_farm_cs',
      'list_cenv',
      'list_ccom',
      'list_ccom_connectivity',
      'list_collar',
      'list_collar_delay',
      'list_collar_operational_status',
      'list_farm',
      'list_module',
      'list_release_notes',
      'list_role',
      'list_semen',
      'list_system_job',
      'list_treatment',
      'update_permission',
      'view_collar_status',
      'view_data_view',
      'create_help',
      'update_help',
      'view_farm_data_delays',
      'view_batch_data_delays',
      'list_mail_recipient',
      'list_farm_reporting_collar',
    ],
    farm: [
      /*CATTLE*/
      'list_animal',
      'list_batch',
      'relate_animal_batch',
      /*REPRODUCTION*/
      'list_reproduction_event',
      'list_reproduction_alert',
      'list_protocol_schedule',
      'list_reproduction_event',
      'list_farm_job',
      'create_reproduction_event',
      'list_farm_semen',
      'list_farm_inseminator',
      'list_mating',
      'list_embryo_donor',
      /*CRIA/RECRIA */
      'list_heifer_health_event',
      /*HEALTH*/
      'list_health_event',
      'list_health_alert',
      'list_health_event_with_filter',
      /*NUTRITION & MILK*/
      'list_batch',
      'list_milk_production',
      'list_milk_quality',
      'list_health_event',
      'list_ingredient',
      'list_diet',
      /*HANDLING*/
      'list_handling_event',
      /*MONITORING*/
      'list_cenv',
      'list_device_point',
      'list_collar',
      'list_collar_alert',
      'list_collar_review_on_farm',
      'list_farm_collar_alert',
      'view_temperature_history',
      'view_expired_cow_baby',
      /*COW ANALYTICS*/
      'view_conception_analytics',
      'view_discard_analytics',
      'view_health_analytics',
      'view_heat_return_analytics',
      'view_service_analytics',
    ],
  })

  function checkSelected(item) {
    let selected = false
    if (item.link) {
      selected = routerMatch(item)
    } else {
      selected = !isEmpty(item.items?.filter(i => routerMatch(i)))
    }

    return selected
  }

  let modules = {
    b2b: [
      //Home
      {
        enabled: true,
        key: 'access',
        subheader: 'Dashboards',
        link: '/',
        icon: <HomeIcon fontSize={21} />,
        label: Translate({ messageKey: 'accesses' }),
      },
      //Data View
      {
        enabled: permissions.system.viewDataView,
        key: 'data-view',
        subheader: 'Data Analytics',
        link: `/data-view`,
        icon: <DataAnalyticsIcon fontSize={21} />,
        label: Translate({ messageKey: 'data_view' }),
      },
      //Farm Database
      {
        key: 'database',
        subheader: 'Farm',
        icon: <FarmDatabase fontSize={21} />,
        label: Translate({ messageKey: 'farm_database' }),
        collapsable: true,
        collapseKey: 'database',
        items: [
          {
            enabled: permissions.system.listSemen,
            key: 'semen',
            link: '/database/semen',
            label: Translate({ messageKey: 'bull_or_semen' }),
          },
          {
            enabled: permissions.system.listTreatment,
            key: 'treatment',
            link: '/database/treatment',
            label: Translate({ messageKey: 'treatment_plural' }),
          },
          {
            enabled:
              permissions.system.createHelp || permissions.system.updateHelp,
            key: 'help-pages',
            link: '/database/help-pages',
            label: Translate({ messageKey: 'help_center' }),
          },
        ],
      },
      //Contas
      {
        key: 'accounts',
        subheader: 'System',
        icon: <AccountIcon fontSize={21} />,
        label: Translate({ messageKey: 'account_plural' }),
        collapsable: true,
        collapseKey: 'accounts',
        items: [
          {
            enabled: farm_count || permissions.system.createFarm,
            key: 'farms',
            link: '/accounts/farm',
            label: Translate({ messageKey: 'farm_plural' }),
          },
          {
            enabled: has_company || permissions.system.createCompany,
            key: 'list',
            link: '/accounts/company',
            label: Translate({ messageKey: 'company_plural' }),
          },
        ],
      },
      //CS
      {
        key: 'cs',
        icon: <Icon path={mdiFaceAgent} size={1} />,
        label: Translate({ messageKey: 'cs' }),
        collapsable: true,
        collapseKey: 'cs',
        items: [
          {
            enabled: permissions.system.listAccountFarmCs,
            key: 'customer-center',
            link: '/cs/customer-center',
            label: Translate({ messageKey: 'customer_center' }),
          },
          {
            enabled: permissions.system.listCampaign,
            key: 'nps',
            link: '/cs/nps/campaigns',
            label: 'NPS',
          },
        ],
      },
      //C-tech
      {
        key: 'c-tech',
        icon: <CTechIcon fontSize={21} />,
        label: Translate({ messageKey: 'c_tech' }),
        collapsable: true,
        collapseKey: 'c-tech',
        items: [
          {
            enabled: permissions.system.listCollarOperationalStatus,
            key: 'collar-status',
            link: '/system/inventory/c-tech/status',
            label: Translate({ messageKey: 'operational_status' }),
          },
          {
            enabled: permissions.system.listCollar,
            key: 'collar',
            exact: true,
            link: '/system/inventory/c-tech/list',
            label: Translate({ messageKey: 'device_list' }),
          },
          {
            enabled: permissions.system.listCollar,
            key: 'collar-break',
            link: '/system/inventory/c-tech/break',
            label: Translate({ messageKey: 'break' }),
          },
        ],
      },
      //C-env
      {
        key: 'c-env',
        icon: <CowComfortIcon fontSize={21} />,
        label: Translate({ messageKey: 'cow_comfort' }),
        collapsable: true,
        collapseKey: 'c-env',
        items: [
          {
            enabled: permissions.system.listCenv,
            key: 'cenv-status',
            exact: true,
            link: '/system/inventory/c-env',
            label: Translate({ messageKey: 'device_list' }),
          },
        ],
      },
      //C-com
      {
        key: 'c-com',
        icon: <Icon path={mdiAccessPointNetwork} size={1} />,
        label: Translate({ messageKey: 'c_com' }),
        collapsable: true,
        collapseKey: 'c-com',
        items: [
          {
            enabled: permissions.system.listCcom,
            key: 'ccom-status',
            link: '/system/inventory/c-com/status',

            label: Translate({ messageKey: 'operational_status' }),
          },
          {
            enabled: permissions.system.listCcom,
            key: 'ccom',
            exact: true,
            link: '/system/inventory/c-com',
            label: Translate({ messageKey: 'device_list' }),
          },
          {
            enabled:
              permissions.system.viewFarmDataDelays ||
              permissions.system.viewBatchDataDelays,
            key: 'ccom-interval',
            link: '/system/inventory/c-com/interval',

            label: Translate({ messageKey: 'data_interval' }),
          },
          {
            enabled: permissions.system.listFarmReportingCollar,
            key: 'report-fails',
            link: '/system/inventory/c-com/report-fails',

            label: Translate({ messageKey: 'report_fails' }),
          },
        ],
      },
      //Root
      {
        key: 'root',
        icon: <Icon path={mdiShieldLock} size={1} />,
        label: Translate({ messageKey: 'root' }),
        collapsable: true,
        collapseKey: 'root',
        items: [
          {
            enabled: permissions.system.listModule,
            key: 'modules',
            link: '/system/settings/modules',
            label: Translate({ messageKey: 'module_plural' }),
          },
          {
            enabled: permissions.system.updatePermission,
            key: 'collar',
            link: '/system/settings/permissions',
            label: Translate({ messageKey: 'permission_plural' }),
          },
          {
            enabled: permissions.system.listRole,
            key: 'roles',
            link: '/system/settings/roles',
            label: Translate({ messageKey: 'role_plural' }),
          },
          {
            enabled: permissions.system.listReleaseNotes,
            key: 'release-notes',
            link: '/system/settings/release-notes',
            label: Translate({ messageKey: 'release_notes' }),
          },
          {
            enabled: permissions.system.listMailRecipient,
            key: 'mails',
            link: '/system/settings/mails',
            label: Translate({ messageKey: 'transactional_emails' }),
          },
          {
            enabled: permissions.system.accessTelescope,
            key: 'tests',
            link: '/system/settings/tests',
            label: Translate({ messageKey: 'test_plural' }),
          },
        ],
      },
      //Import
      {
        enabled: permissions.system.listSystemJob,
        key: 'system-import',
        link: `/system/import`,
        icon: <ImportIcon fontSize={21} />,
        label: Translate({ messageKey: 'import_data' }),
      },
    ],
    b2c: [
      //Home
      {
        enabled: true,
        exact: true,
        key: 'home-dashboard',
        subheader: 'Menu',
        link: `/farm/${currentFarmSlug}`,
        icon: <HomeIcon fontSize={21} />,
        label: Translate({ messageKey: 'home' }),
      },
      //Rebanho
      {
        key: 'batches-cattle',
        icon: <AnimalIcon fontSize={21} />,
        label: Translate({ messageKey: 'herd' }),
        collapsable: true,
        collapseKey: 'herd',
        items: [
          {
            enabled: permissions.farm.listAnimal,
            key: 'animal-management',
            link: `/farm/${currentFarmSlug}/modules/herd/animals`,
            label: Translate({ messageKey: 'animal_plural' }),
          },
          {
            enabled: permissions.farm.listBatch,
            key: 'batch-management',
            link: `/farm/${currentFarmSlug}/modules/herd/batch`,
            label: Translate({ messageKey: 'batch_plural' }),
          },
          {
            enabled: permissions.farm.relateAnimalBatch,
            key: 'batch-handling',
            link: `/farm/${currentFarmSlug}/modules/herd/manage`,
            label: Translate({ messageKey: 'change_group_plural' }),
          },
        ],
      },
      //Cria/Recria
      {
        key: 'rearing',
        icon: <RearingIcon fontSize={21} />,
        label: Translate({
          messageKey: ['rearing', 'raising'],
          separator: '/',
        }),
        collapsable: true,
        collapseKey: 'rearing',
        items: [
          {
            enabled: permissions.farm.listHeiferHealthEvent,
            key: 'rearing-health-management',
            link: `/farm/${currentFarmSlug}/modules/health/infirmary-baby`,
            label: Translate({ messageKey: 'raising_infirmary' }),
          },
          {
            enabled: permissions.farm.listAnimal,
            key: 'rearing-weaning-management',
            link: `/farm/${currentFarmSlug}/modules/rearing/weaning`,
            label: Translate({ messageKey: 'weaning' }),
          },
        ],
      },
      //Reproducao
      {
        key: 'reproduction',
        icon: <ReproductionIcon fontSize={21} />,
        label: Translate({ messageKey: 'reproduction' }),
        collapsable: true,
        collapseKey: 'reproduction',
        items: [
          {
            enabled:
              permissions.farm.listReproductionEvent &&
              permissions.farm.listReproductionAlert,
            key: 'reproduction-alerts',
            link: `/farm/${currentFarmSlug}/modules/reproduction/heat`,
            label: Translate({ messageKey: 'heat_alert_plural' }),
          },
          {
            enabled: permissions.farm.listAnimal,
            key: 'reproduction-gynecologic-examination',
            link: `/farm/${currentFarmSlug}/modules/reproduction/gynecologic`,
            label: Translate({ messageKey: 'gynecologic_examination' }),
          },
          {
            enabled: permissions.farm.viewHeatReturnAnalytics,
            key: 'reproduction-heat-return',
            link: `/farm/${currentFarmSlug}/cow-analytics/heat-return`,
            label: Translate({ messageKey: 'heat_return' }),
          },
          {
            enabled:
              permissions.farm.listAnimal &&
              permissions.farm.listReproductionEvent,
            key: 'reproduction-pregnancy-diagnosis',
            link: `/farm/${currentFarmSlug}/modules/reproduction/pregnancy`,
            label: Translate({ messageKey: 'pregnancy_diagnosis' }),
          },
          {
            enabled: permissions.farm.listProtocolSchedule,
            key: 'reproduction-protocol-schedule',
            link: `/farm/${currentFarmSlug}/modules/reproduction/protocol`,
            label: Translate({ messageKey: 'protocol_plural' }),
          },
          {
            enabled: permissions.farm.listReproductionEvent,
            key: 'reproduction-drying',
            link: `/farm/${currentFarmSlug}/modules/reproduction/drying`,
            label: Translate({ messageKey: 'drying' }),
          },
          {
            enabled:
              permissions.farm.listAnimal &&
              permissions.farm.listReproductionEvent,
            key: 'reproduction-pre-calving-prediction',
            link: `/farm/${currentFarmSlug}/modules/reproduction/pre-calving-prediction`,
            label: Translate({ messageKey: 'pre_calving' }),
          },
          {
            enabled: permissions.farm.listReproductionEvent,
            key: 'reproduction-calving',
            link: `/farm/${currentFarmSlug}/modules/reproduction/calving`,
            label: Translate({ messageKey: 'calving_plural' }),
          },
          {
            enabled:
              permissions.farm.listAnimal &&
              permissions.farm.listReproductionEvent,
            key: 'reproduction-ppi',
            link: `/farm/${currentFarmSlug}/cow-analytics/transition/challenged`,
            label: Translate({ messageKey: 'ppi_animal' }),
          },
          {
            enabled: permissions.farm.listFarmSemen,
            key: 'farm-bull-semen',
            link: `/farm/${currentFarmSlug}/modules/reproduction/bull-semen`,
            label: Translate({ messageKey: 'bull_or_semen' }),
          },
          {
            enabled: permissions.farm.listEmbryoDonor,
            key: 'farm-embryo-donor',
            link: `/farm/${currentFarmSlug}/modules/reproduction/embryo-donor`,
            label: Translate({ messageKey: 'donor_plural' }),
          },
          {
            enabled: permissions.farm.listFarmInseminator,
            key: 'farm-inseminator',
            link: `/farm/${currentFarmSlug}/modules/reproduction/inseminator`,
            label: Translate({ messageKey: 'inseminator' }),
          },
          {
            enabled: permissions.farm.listMating,
            key: 'farm-mating',
            link: `/farm/${currentFarmSlug}/modules/reproduction/mating`,
            label: Translate({ messageKey: 'mating' }),
          },
        ],
      },
      //Saude
      {
        key: 'health',
        icon: <HealthIcon fontSize={21} />,
        label: Translate({ messageKey: 'health' }),
        collapsable: true,
        collapseKey: 'health',
        items: [
          {
            enabled:
              permissions.farm.listHealthEvent &&
              permissions.farm.listHealthAlert,
            key: 'health-management',
            link: `/farm/${currentFarmSlug}/modules/health/infirmary`,
            label: Translate({ messageKey: 'infirmary' }),
          },
          {
            enabled: permissions.farm.listHeiferHealthEvent,
            key: 'health-baby-management',
            link: `/farm/${currentFarmSlug}/modules/health/infirmary-baby`,
            label: Translate({ messageKey: 'raising_infirmary' }),
          },
          {
            enabled:
              permissions.farm.listHealthEvent &&
              permissions.farm.listHealthAlert,
            key: 'health-challenge',
            link: `/farm/${currentFarmSlug}/modules/health/challenge`,
            label: Translate({ messageKey: 'challenged_animals' }),
          },
          {
            enabled: permissions.farm.listHealthEventWithFilter,
            key: 'health-history',
            link: `/farm/${currentFarmSlug}/modules/health/history`,
            label: Translate({
              messageKey: 'item_history',
              params: {
                item: Translate({ messageKey: 'event_plural', lower: true }),
              },
            }),
          },
        ],
      },
      //Nutricao
      {
        key: 'nutrition',
        icon: <NutritionIcon fontSize={21} />,
        label: Translate({ messageKey: 'nutrition' }),
        collapsable: true,
        collapseKey: 'nutrition',
        items: [
          {
            enabled:
              permissions.farm.listBatch && permissions.farm.listMilkProduction,
            key: 'nutrition-management',
            link: `/farm/${currentFarmSlug}/modules/precision-nutrition/monitored`,
            label: Translate({
              messageKey: 'item_monitored_plural',
              params: {
                item: Translate({ messageKey: 'batch_plural', lower: true }),
              },
            }),
          },
          {
            enabled: permissions.farm.listIngredient,
            key: 'nutrition-management-ingredient',
            link: `/farm/${currentFarmSlug}/modules/precision-nutrition/ingredient`,
            label: Translate({ messageKey: 'ingredient_management' }),
          },
          {
            enabled: permissions.farm.listDiet,
            key: 'nutrition-register-diet',
            link: `/farm/${currentFarmSlug}/modules/precision-nutrition/diet`,
            label: Translate({
              messageKey: 'registration_of',
              params: {
                item: Translate({ messageKey: 'diet_label', lower: true }),
              },
            }),
          },
        ],
      },
      //Controle Leiteiro
      {
        key: 'milk',
        icon: <ProductionIcon fontSize={21} />,
        label: Translate({ messageKey: 'milk_control' }),
        collapsable: true,
        collapseKey: 'milk',
        items: [
          {
            enabled: permissions.farm.listMilkProduction,
            key: 'milk-control',
            link: `/farm/${currentFarmSlug}/modules/milk-control/analysis`,
            label: Translate({ messageKey: 'production_analysis' }),
          },
          {
            enabled:
              permissions.farm.listAnimal &&
              permissions.farm.listMilkProduction,
            key: 'milk-control-production',
            link: `/farm/${currentFarmSlug}/modules/milk-control/production`,
            label: Translate({ messageKey: 'production_register' }),
          },
          {
            enabled: permissions.farm.listHealthEvent,
            key: 'milk-control-discard',
            link: `/farm/${currentFarmSlug}/modules/milk-control/discard`,
            label: Translate({ messageKey: 'milk_discard' }),
          },
          {
            enabled: permissions.farm.listMilkQuality,
            key: 'milk-control-quality',
            link: `/farm/${currentFarmSlug}/modules/milk-control/quality`,
            label: Translate({ messageKey: 'milk_quality' }),
          },
          {
            enabled:
              permissions.farm.listAnimal ||
              permissions.farm.listReproductionEvent,
            key: 'milk-control-induced',
            link: permissions.farm.listReproductionEvent
              ? `/farm/${currentFarmSlug}/modules/milk-control/induced/marked`
              : `/farm/${currentFarmSlug}/modules/milk-control/induced/realized`,
            label: Translate({ messageKey: 'lactation_induction' }),
          },
        ],
      },
      //Estresse Termico
      {
        key: 'stress',
        icon: <Icon path={mdiThermometerLines} size={1} />,
        label: Translate({ messageKey: 'thermal_stress' }),
        collapsable: true,
        collapseKey: 'stress',
        items: [
          {
            enabled: permissions.farm.viewTemperatureHistory,
            key: 'thi',
            link: `/farm/${currentFarmSlug}/modules/thermal-stress`,
            label: Translate({ messageKey: 'overview' }),
          },
        ],
      },
      //Evento de Manejo
      {
        key: 'handling',
        icon: <HandlingIcon fontSize={21} />,
        label: Translate({ messageKey: 'handling_events' }),
        collapsable: true,
        collapseKey: 'handling',
        items: [
          {
            enabled: permissions.farm.listHandlingEvent,
            key: 'handling-list',
            link: `/farm/${currentFarmSlug}/modules/cattle-handling`,
            label: Translate({ messageKey: 'handling_register' }),
          },
        ],
      },
      //Monitoramento
      {
        key: 'monitoring',
        icon: <Icon path={mdiWifi} size={1} />,
        label: Translate({ messageKey: 'monitoring' }),
        collapsable: true,
        collapseKey: 'monitoring',
        items: [
          {
            enabled: permissions.farm.listDevicePoint,
            key: 'overview',
            link: `/farm/${currentFarmSlug}/modules/monitoring/overview`,
            label: Translate({ messageKey: 'overview' }),
          },
          {
            enabled: permissions.farm.listAnimal,
            key: 'animals-list',
            link: `/farm/${currentFarmSlug}/modules/monitoring/animals`,
            label: Translate({
              messageKey: 'item_monitored_plural',
              params: {
                item: Translate({ messageKey: 'animal_plural', lower: true }),
              },
            }),
          },
          {
            enabled:
              permissions.farm.listBatch && permissions.farm.listMilkProduction,
            key: 'batchs-list',
            link: `/farm/${currentFarmSlug}/modules/precision-nutrition/monitored`,
            label: Translate({
              messageKey: 'item_monitored_plural',
              params: {
                item: Translate({ messageKey: 'batch_plural', lower: true }),
              },
            }),
          },
          {
            enabled:
              permissions.farm.listCollar ||
              permissions.farm.viewExpiredCowBaby ||
              permissions.farm.listCollarReviewOnFarm,
            key: 'collars-list',
            link: `/farm/${currentFarmSlug}/modules/monitoring/c-tech`,
            exact: true,
            label: Translate({ messageKey: 'collar_managment_plural' }),
          },
          {
            enabled:
              permissions.farm.listCenv && permissions.farm.listDevicePoint,
            key: 'cenv-list',
            link: permissions.farm.listCenv
              ? `/farm/${currentFarmSlug}/modules/monitoring/c-env`
              : `/farm/${currentFarmSlug}/modules/monitoring/c-env/points`,
            label: Translate({ messageKey: 'cow_comfort_managment' }),
          },
        ],
      },
      //CowAnalytics
      {
        enabled:
          permissions.farm.viewServiceAnalytics ||
          permissions.farm.viewConceptionAnalytics ||
          permissions.farm.viewHeatReturnAnalytics ||
          permissions.farm.viewHealthAnalytics ||
          permissions.farm.viewDiscardAnalytics,
        key: 'cow-analytics',
        link: `/farm/${currentFarmSlug}/cow-analytics`,
        icon: <Icon path={mdiChartLine} size={1} />,
        label: Translate({ messageKey: 'cow_analytics' }),
      },

      //Import
      {
        enabled: permissions.farm.listFarmJob,
        key: 'import',
        link: `/farm/${currentFarmSlug}/import`,
        icon: <ImportIcon fontSize={21} />,
        label: Translate({ messageKey: 'import_data' }),
      },

      //Export
      {
        enabled: permissions.farm.listFarmJob,
        key: 'export',
        link: `/farm/${currentFarmSlug}/export`,
        icon: <ExportIcon fontSize={21} />,
        label: Translate({ messageKey: 'export_data' }),
      },
    ],
  }

  const MyListItem = forwardRef((props, ref) => {
    const { children, selected, ...rest } = props
    return (
      <ListItem
        ref={ref}
        component={Link}
        classes={{ root: classes.linkHover }}
        style={
          selected
            ? {
                boxShadow: `inset 3px 0 0 ${Palette.primary.accent[2]}`,
                color: Palette.primary.accent[2],
                backgroundColor: 'rgba(0,0,0,.1)',
              }
            : {}
        }
        color="inherit"
        {...rest}
      >
        {children}
      </ListItem>
    )
  })

  MyListItem.displayName = 'MyListItem'

  const renderMenuItems = options => {
    // render each item list
    const renderListItem = props => {
      const { key, link, icon, label, collapsable, collapseKey, items } = props

      const handleOpen = () => {
        setOpen(prevState => (prevState === collapseKey ? null : collapseKey))
      }

      if (collapsable) {
        return (
          <React.Fragment>
            <ListItemButton key={key} onClick={handleOpen}>
              <ListItemIcon classes={{ root: classes.icon }}>
                {icon}
              </ListItemIcon>
              <ListItemText
                primary={label}
                classes={{
                  root: classes.parent,
                  primary: classes.text,
                }}
              />
              {open === collapseKey ? (
                <Icon path={mdiChevronUp} size={1} />
              ) : (
                <Icon path={mdiChevronDown} size={1} />
              )}
            </ListItemButton>
            <Collapse in={open === collapseKey} timeout="auto">
              <List component="div">
                {items.map(item => {
                  const { key, link, label, enabled } = item
                  return (
                    enabled && (
                      <MyListItem
                        key={key}
                        to={link}
                        selected={checkSelected(item)}
                        className={classes.nested}
                      >
                        <ListItemText
                          primary={label}
                          classes={{ primary: classes.text }}
                        />
                      </MyListItem>
                    )
                  )
                })}
              </List>
            </Collapse>
          </React.Fragment>
        )
      }
      return (
        <MyListItem key={key} to={link} selected={checkSelected(props)}>
          <ListItemIcon classes={{ root: classes.icon }}>{icon}</ListItemIcon>
          <ListItemText
            primary={label}
            classes={{
              root: classes.parent,
              primary: classes.text,
            }}
          />
        </MyListItem>
      )
    }

    return options
      .filter(item => {
        if (item.enabled) {
          return true
        }
        if (item.items) {
          return item.items.some(subItem => subItem.enabled)
        }
        return false
      })
      ?.map((each, i) => {
        const {
          key,
          subheader,
          link,
          icon,
          label,
          collapsable,
          collapseKey,
          items,
          exact,
        } = each

        return (
          <List
            key={`${key}-${i}`}
            classes={{ root: classes.listRoot }}
            subheader={
              subheader ? (
                <ListSubheader
                  component="div"
                  id="nested-list-subheader-farm"
                  classes={{ root: classes.subHeader }}
                  disableSticky
                >
                  <Typography variant="body1" gutterBottom component="span">
                    {subheader}
                  </Typography>
                </ListSubheader>
              ) : undefined
            }
          >
            {renderListItem({
              key,
              link,
              icon,
              label,
              collapsable,
              collapseKey,
              items,
              exact,
            })}
          </List>
        )
      })
  }

  useMemo(() => {
    const b2bCollapseKey = modules.b2b?.find(
      i => i.collapsable && checkSelected(i),
    )?.collapseKey

    const b2cCollapseKey = modules.b2c?.find(
      i => i.collapsable && checkSelected(i),
    )?.collapseKey

    setOpen(b2bCollapseKey ?? b2cCollapseKey)
  }, [])

  /* SYSTEM MODULES */
  const OutFarm = () => (
    <List key="system-menu">{renderMenuItems(modules.b2b)}</List>
  )

  /* FARM MODULES */
  const InFarm = () => (
    <List key="in-farm-menu">{renderMenuItems(modules.b2c)}</List>
  )

  return (
    <React.Fragment>
      {!currentFarmSlug || isBusinessEnvironment() ? <OutFarm /> : <InFarm />}
    </React.Fragment>
  )
}

export default Menu
